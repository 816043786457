@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');

#fm-toolbar-wrapper #fm-toolbar {
    font-family: 'Electrolize', sans-serif !important;
    background-color: white !important;
    color: #0a2342 !important;
}

.container-fluid {
    padding: 0;
    font-family: 'Electrolize', sans-serif !important;
  }
  
  .body  {
  font-family: 'Electrolize', sans-serif !important;
  
  }
  
  .fm-sheet-header {
    font-family: 'Electrolize', sans-serif !important;
    background-color: white !important;
    color: #0a2342 !important;
  }
  
  #fm-toolbar-wrapper #fm-toolbar {
    font-family: 'Electrolize', sans-serif !important;
    background-color: white !important;
    color: #0a2342 !important;
  }
  
  #fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter {
    font-family: 'Electrolize', sans-serif !important;
      background-color: white !important;
  
      color: #0a2342 !important;
      
  }

  
  #fm-pivot-view .fm-grid-row,
  #fm-pivot-view .fm-grid-row-mobile {
    height: 20px !important;
    min-height: 20px;
    min-width: 80px !important;
    font-family: 'Electrolize', sans-serif !important;
    font-size: 20px !important;
  }
  
  .fm-header {
    font-family: 'Electrolize', sans-serif !important;
    background-color: white !important;
    color: #0a2342 !important;
    text-align: center;
  }
  
  #fm-row {
    font-family: 'Electrolize', sans-serif !important;    
    color: #0a2342 !important;
  }
  
  .fm-grid-view .fm-cell {
    font-family: 'Electrolize', sans-serif !important;

  }
  
  #fm-pivot-view .fm-grid-layout div.fm-cell.fm-empty {
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: white !important;
  
  }
  
  #fm-pivot-view .fm-grid-view div.fm-cell.fm-grand-total.fm-grand-total-c.fm-total-r {
    font-family: 'Electrolize', sans-serif !important;
  
  }
  
  #fm-pivot-view .fm-grid-layout .fm-cols-sheet div.fm-row>div, #fm-pivot-view .fm-grid-layout .fm-data-sheet div.fm-row>div {
    font-family: 'Electrolize', sans-serif !important;  
    color: #0a2342 !important;
    text-align: center !important;
  
  }

  #fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-content .fm-cell {
    text-align: center !important;
    vertical-align: center !important;
  }
  
  .main-sidebar {
      background-image: none !important;
  }

  #fm-pivot-view .fm-fields-view-wrap button.fm-btn-open-fields {
    background-color: white;
  }

  #fm-pivot-view .fm-fields-view-wrap button.fm-btn-open-fields:before {
    color: #1E1E1F;
    font-size: 30px;
    font-weight: bolder;
  }

  #fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-content {
    background-color: white !important;
  }

  #fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-content .fm-cell {
    background-color: white !important;
    text-shadow: none !important
  }

  #fm-pivot-view .fm-grid-layout .fm-draggable {
    
    border-color: #1E1E1F;
  }

  .fm-charts-color-1 {
    fill: rgb(0, 109, 238) !important;
  }

  
  .fm-charts-color-2 {
    fill: rgb(10, 35, 66) !important;
  }

  #fm-pivot-view span.fm-ui-label.fm-pivot-title {
    color: #1E1E1F;
    font-size: 20px;
    font-weight: bolder;
    font-family: 'Electrolize', sans-serif !important;
    text-align: left
  }